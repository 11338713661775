.carousel-slider {
  height: 400px;

}
.control-dots li {
  background: #f7931e !important;
}

@media (max-width: 576px) {
  .carousel-slider {
    height: 200px;
  }
}