html,
body {
  margin: 0;
  background: #ebedef;
}

.btn-Whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
}

.btn-Whatsapp svg {
  margin-top: 14px;
}
.btn-Whatsapp:hover svg {
  transform: scale(1.4);
}

/*MODAL DOS ANUNCIOS*/
.modal-content {
  max-width: 1050px;
}
.modal-header .modal-title {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 688px) {
  .modal-header .modal-title {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .modal-title div {
    width: 100%;
  }
}

.modal-header button {
  width: 5%;
}

.modal-footer {
  width: 95%;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 688px) {
  .modal-footer {
    flex-direction: column;
  }
}

.modal-footer ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.li {
  transition: all 200ms ease-in;
}
.status {
  min-width: 180px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}
.status h4 {
  font-weight: 600;
  margin-top: 20px;
}
.status:before {
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #66dc71;
}
.li.complete .status:before {
  background-color: #66dc71;
  border: none;
  transition: all 200ms ease-in;
}
.li.complete .status h4 {
  color: #66dc71;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #fbd3a2;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
