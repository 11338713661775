.wrapper {
    position: fixed;
    z-index: 9999999;
    pointer-events: unset;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .overlay-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .w-auto {
    width: auto;
  }
  .w-auto-important {
    width: auto !important;
  }
  
  .over-bar {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
  .running {
    animation: spin 1.6s linear infinite;
    margin-bottom: 10px;
    overflow-y: hidden;
  }
  
  .car {
    animation: show 0.4s, moveUpDown 1s linear infinite;
    width: 80px;
    position: relative;
    left: 29px;
    bottom: 90px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .running2 {
    animation: spin2 2.1s linear infinite;
    margin-left: -113px;
    overflow-y: hidden;
  }
  
  @keyframes spin2 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  @keyframes moveUpDown {
    0%,
    100% {
      bottom: 85px;
    }
    50% {
      bottom: 90px;
    }
  }
  
  @keyframes show {
    0%,
    25% {
      transform: scale(0.25);
    }
    50% {
      transform: scale(0.5);
    }
    75% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1);
    }
  }
  